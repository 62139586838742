<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <label>{{ $t("components.views.settings.language.select_the_language_of_your_preference") }}:</label>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-0 pt-0">
        <Select
          v-model="selectLang"
          item-text="desc"
          :items="lang"
          item-value="lang"
          hide-details
          dense
          :selected="$i18n.locale"
          :label="$t('components.views.settings.language.language')"
          @change="setLocale()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { loadMessages } from "@/plugins/i18n";

export default {
  data() {
    return {
      selectLang: "es",
      lang: [
        {
          lang: "es",
          desc: this.$t("components.views.settings.language.es_version"),
        },
        {
          lang: "en",
          desc: this.$t("components.views.settings.language.en_version"),
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      locale: "lang/locale",
      locales: "lang/locales",
    }),
  },

  metaInfo() {
    return {
      title: this.$t("views.settings.language"),
    };
  },

  methods: {
    setLocale() {
      let locale = this.selectLang;
      if (this.$i18n.locale !== this.selectLang) {
        loadMessages(this.selectLang);
        this.$store.dispatch("lang/setLocale", { locale });
        localStorage.removeItem("colservicios");
        location.reload();
      }
    },
  },
};
</script>

<style scoped>


.title-card,
.title-card :deep(.fa-solid) {
  color: #fff !important;
}
</style>
